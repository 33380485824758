// Buttons
$primaryBtnBGColor : $primaryColor;
$primaryBtnTextColor : $primaryTextColor;
$primaryBtnHoverColor : $primaryDarkenColor;
$primaryBtnBorderColor : $primaryColor;
$secondaryBtnBGColor : $primaryTextColor;
$secondaryBtnTextColor : $primaryColor;
$secondaryBtnHoverColor : $primaryTextColor;
$secondaryBtnBorderColor : $primaryLightenColor;
$secondaryBtnBorderHoverColor : $primaryColor;

// Topbar
$topBarBGColor : $secondaryColor;
$topBarHeight : 70px;

// Logo
$logoBGColor :$primaryColor;

// Icons
$iconHoverColor : $primaryColor;
$iconActiveColor : $primaryColor;

// Notifications
$notificationsHeaderColor : $primaryColor;
$notificationsBorderColor :  $primaryColor;
$notificationsLblColor :  $primaryTextColor;

.primary {
  color: $primaryColor;
}
.secondary {
  color: $secondaryColor;
}
.splash-screen {
  // For Demo
  // background-color: #1496A7;

  // For Tectoro
  background-color: #366ccc;
  height: 100%;
}
.db-tabs-alg {
  border-bottom: 3px solid $primaryColor;
}

p, hr {
  color: $primaryColor;
}
p {
  margin: 0px;
}
.on-hover:hover {
  color: $secondaryColor;
}

.export-label {
  color: $secondaryColor;
  cursor: pointer;
}
.export-label:hover {
  color: $primaryColor;
  cursor: pointer;
}
.left-border {
  border-left: 1px solid $secondaryColor;
}

.custom-tab-inactive {
  border-bottom: 3px solid #ccc;
  padding-bottom: 5px;
  padding-left: 5px;
  color: #757575;
  cursor: pointer;
}
.hrInCard,
 .widget-header, .csopId-color, .filter-funds, .show-more-lable:hover, .blueUrl:hover
{
  color: $primaryColor;
}
.landing-home-btn{
  color: $primaryColor !important;
}
 .nav-label, .aum-label, .aum-value, .td-label, .exposure-style, .exposure, .cal-allign,
.quantity, .p-2, .p-3, .fund-text, .total-aum, .pdf-alg{
  color: $secondaryColor;
}
 .clearfix:hover, .approve-btn:hover {
  color:$secondaryColor;
}
.custom-tab-active {
  border-bottom: 3px solid $secondaryColor;
}

.back-bg,.finance-head,.clearfix, .header-alg, .bg-blue, .approve-btn {
  background-color: $primaryColor;
}

.loading:not(:required):after {
  -webkit-box-shadow: $primaryColor 1.5em 0 0 0, $primaryColor 1.1em 1.1em 0 0, $primaryColor 0 1.5em 0 0, $primaryColor -1.1em 1.1em 0 0, $primaryColor -1.5em 0 0 0, $primaryColor -1.1em -1.1em 0 0, $primaryColor 0 -1.5em 0 0, $primaryColor 1.1em -1.1em 0 0;
  box-shadow: $primaryColor 1.5em 0 0 0, $primaryColor 1.1em 1.1em 0 0, $primaryColor 0 1.5em 0 0, $primaryColor -1.1em 1.1em 0 0, $primaryColor -1.5em 0 0 0, $primaryColor -1.1em -1.1em 0 0, $primaryColor 0 -1.5em 0 0, $primaryColor 1.1em -1.1em 0 0;
}

.bg-image {
  background: $loginBg;
}
.activeStep {
  border-bottom: 3px solid $primaryColor;
  padding-bottom: 10px;
  color: $primaryColor;
}
.ff-tabs-alg {
  color: $primaryColor;
  border-bottom: 5px solid $primaryColor;
}
.ff-tabs-alg-bottom{
  color: $primaryColor;
  border-bottom: 3px solid $primaryColor;
}
.catageory-font {
  .name {
    color: $primaryColor;
  }
}
.widget-content {
  .edit-save-icon{
    color: $primaryColor !important;
  }
}
.widget-table {
  table {
    th , td , fund-info-td-lbl {
      color: $primaryColor !important;
    }
  }
}

.container .checkmark:after {
  border: solid $primaryColor;
}
.menu-collapse-width, .menu-init-width {
  background: $leftMenu;
}


// Firm-Level Liquidation
.bar-one {
  background-color : $barOne;
}
.bar-two {
  background-color : $barTwo;
}
.bar-three {
  background-color : $barThree;
}
.bar-four {
  background-color : $barFour;
}
.bar-five {
  background-color : $barFive;
}
.bar-twentyTwo {
  background-color : $barTwentytwo;
}
.bar-sixtyFive {
  background-color : $barSixtyFive;
}
.bar-oneThirty {
  background-color : $barOneThirty;
}
.bar-twoFiftyTwo {
  background-color : $barTwoFiftyTwo;
}
.bar-moreThanTwoFiftyTwo {
  background-color : $barMoreTwoFiftyTwo;
}



// p {
//   color: $primaryColor;
// }
.selectedItem {
  color: $primaryColor;
}
.suspend-stock-p-table-one {
  .ui-table .ui-table-tbody > tr > td {
    border-color: $secondaryColor;
  }
}
.suspend-stock-p-table-two {
  .ui-table .ui-table-thead > tr > th {
    background-color: $secondaryColor;
  }
  .ui-table .ui-table-tbody > tr > td {
    border-color: $secondaryColor;
  }
}
.suspend-stock-p-table-three {
  .ui-table .ui-table-tbody > tr > td {
    border-color: $secondaryColor;
  }
}
.divInvisible {
  border-top: $secondaryColor solid 1px;
  height: 38px;
}

.divInvisibleExport {
  height: 30px;
}

.peer-table-header {
  //for Table-Headers
  .ui-table .ui-table-thead > tr > th {
    color: $primaryColor;
  }
  //For Table-Body
  .ui-table tbody td {
    color: $primaryColor;
  }
}

.priv-tags {
  .ui-state-default a {
    color: $secondaryColor;
  }
}
.privilege-filter {
  th {
    color: $secondaryColor;
  }
}

.db-tabs-alg {
  border-bottom: 3px solid $secondaryColor;
}

/* newsletter */

.newsletter-home {
  .header {
    color:$secondaryColor;
  }
  .newsletter-card {
    .date {
      color:$secondaryColor;
    }
  }
    .centered {
      background-color: $secondaryColor;
    }

    .centered-width {
      background-color: $secondaryColor;
    }

  .card-label {
    color:$secondaryColor;
  }
  .news-label {
    color:$secondaryColor;
  }
}

.back-button {
  background: $secondaryColor;
}

.selected {
  border: 5px solid $secondaryColor;
}



// +++
.gen-email-p-table {
  //for headers of the table
  .ui-table .ui-table-thead > tr > th {
    background-color: $secondaryColor;
  }
  .ui-table .ui-table-tbody > tr > td {
    color: $primaryColor;
  }
}


.peer-asof-date {
  .ui-calendar .ui-calendar-button {
    color: $secondaryColor !important;
  }
}


// Newsletters

.src-item {
  border: 2px solid $secondaryColor;
  .src-icon {
    color: $secondaryColor;
  }
  .src-name {
    color:$primaryColor;
  }
}
.centered {
  background-color: $secondaryColor;
}


.ui-slider-range {
  background: $primaryColor !important;
}
.ui-messages-close {
  color: #fff !important;
}

.b-r-w {
  border-right: 1px solid #ffffff !important;
}
