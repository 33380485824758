
.db-header-alg .ui-datatable .ui-datatable-scrollable-body {
  height: calc(100vh - 380px);
}
.audit-table .ui-datatable .ui-datatable-scrollable-body {
  height: calc(100vh - 500px) !important;
}
.premium-table .ui-datatable .ui-datatable-scrollable-body {
  height: calc(100vh - 600px);
  overflow-y: auto;
}
.add-fund-height {
  height: calc(100vh - 350px) !important;
}

.sec-tbody-height .ui-datatable .ui-datatable-scrollable-body {
  height: calc(100vh - 290px) !important;
}

.table-scroll-f .ui-datatable .ui-datatable-scrollable-body {
  height: calc(100vh - 430px) !important;
}

.trans-tbody .ui-datatable .ui-datatable-scrollable-body {
  height: calc(100vh - 480px) !important;
}

.new-fund-scroll {
  overflow-y: auto;
  height: calc(100vh - 300px) !important;
}

.new-Party-scroll {
  overflow-y: auto;
  height: calc(100vh - 320px) !important;
}

.fund-info-scroll {
  overflow-y: auto;
  height: calc(100vh - 195px) !important;
}
.suspend-stock-scroll {
  overflow-y: auto;
  height: calc(100vh - 190px) !important;
}
.dashboard-scroll {
  overflow-y: auto;
  width: 100%;
  height: calc(100vh - 201px) !important;
}

.userstat-scroll {
  overflow-y: auto;
  height: calc(100vh - 125px) !important;
}

@media screen and (max-width: 1440px) and (min-width: 1024px) {
  .dashboard-x-scroll {
    width: 95em;
    overflow-x: auto;
    white-space: nowrap;
  }
}

@media screen and (min-width: 1600px) {
  #gallery-1 img {
    width: 434px;
  }
}

@media screen and (min-width: 1920px) {
  #gallery-1 img {
    width: 540px;
  }
}
@media only screen and (max-width: 1600px) {
  .tab-alg {
    height: 50px !important;
  }
  .card-header {
    font-size: 13px;
  }
  .custom-tab-active {
    font-size: 12px !important;
  }
  .custom-tab-inactive {
    font-size: 12px !important;
  }
  .snap-text {
    .snap-value {
      font-size: 13px !important;
    }
  }
  .aum-text {
    font-size: 12px !important;
  }
  .aum-arrow {
    padding-right: 5px !important;
  }
  .snap-top-table {
    .snap-top-header {
      font-size: 13px !important;
    }
    .col-one-w {
      width: 70% !important;
    }
    .col-two-w {
      width: 30% !important;
    }
  }
  .user-css {
    font-size: 20px !important;
  }
  .fund-name {
    font-size: 15px !important;
  }
  .fairVal-report-th .ui-datatable {
    font-size: 12px !important;
  }
}

@media screen and (min-width: 750px) and (max-width: 1050px) {
  .text-elipsis {
    width: calc(100vw - 680px) !important;
  }
  .custom-padding {
    padding: 0px 5px 0px 8px !important;
  }
}
